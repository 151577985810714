import React, { memo } from 'react';
import { css } from '@emotion/core';
import Container from '../styled/Container';
import BackgroundImage from '../styled/BackgroundImage';
import { viewports } from '../../utils/variables';

const BottomExcerpt = ({ copy, image }) => {
  return (
    <section css={baseCSS}>
      <BackgroundImage image={image} style={imageCSS} />
      <Container theme="secondary">
        <h5 css={copyCSS}>{copy}</h5>
      </Container>
    </section>
  );
};

export default memo(BottomExcerpt);

const baseCSS = css`
  align-items: center;
  display: flex;
  min-height: 20.25rem;
  padding: 3.125rem 0;
  position: relative;
`;

const imageCSS = css`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  &:after {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
`;

const copyCSS = css`
  color: #fff;
  font-family: 'Dosis';
  font-size: 1.5rem;
  line-height: 125%;
  text-align: center;

  @media ${viewports.medium} {
    font-size: 1.875rem;
  }
`;
