import React, { memo } from 'react';
import { css } from '@emotion/core';
import Container from '../styled/Container';
import {
  SectionHeading,
  CopyHeading,
  BodyText,
  Cta
} from '../styled/Typography';
import BackgroundImage from '../styled/BackgroundImage';
import { styles, viewports } from '../../utils/variables';

const OurProducts = ({ heading, blocks }) => {
  return (
    <section css={baseCSS}>
      <Container>
        <SectionHeading>{heading}</SectionHeading>
        <div css={blocksCSS}>
          {blocks.map(block => (
            <div css={blockCSS} key={block.heading}>
              <BackgroundImage image={block.image} style={imageCSS} />
              <div css={contentCSS}>
                <CopyHeading>{block.heading}</CopyHeading>
                <BodyText style={bodyTextCSS}>{block.copy}</BodyText>
                <Cta to={block.cta_url} style={ctaCSS}>
                  {block.cta}
                </Cta>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default memo(OurProducts);

const baseCSS = css`
  background: #fff;
  text-align: center;

  @media ${viewports.medium} {
    padding: 0 0 6.875rem;
  }
`;

const blocksCSS = css`
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1.125rem 2.5rem;

  @media ${viewports.medium} {
    display: flex;
  }
`;

const blockCSS = css`
  box-shadow: ${styles.boxShadow};
  margin: 0 1.5rem 0.875rem;
  text-align: center;

  @media ${viewports.medium} {
    margin: 0 1.5rem;
    width: 37.5%;
  }
`;

const contentCSS = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

const imageCSS = css`
  height: 13.125rem;
  width: 100%;
`;

const bodyTextCSS = css`
  text-align: left;
`;

const ctaCSS = css`
  margin-top: auto;
`;
