import React, { memo } from 'react';
import { css } from '@emotion/core';
import { SectionHeading, CopyHeading } from '../styled/Typography';
import Container from '../styled/Container';
import { BodyText } from '../styled/Typography';
import SolutionsIcon from './SolutionsIcon';
import { viewports } from '../../utils/variables';

const SupportedSolutions = ({ heading, copy, blocks }) => {
  return (
    <section css={baseCSS}>
      <Container theme="secondary">
        <SectionHeading>{heading}</SectionHeading>
        <BodyText style={bodyTextCSS}>{copy}</BodyText>
        <div css={blocksCSS}>
          {blocks.map(block => (
            <div css={blockCSS} key={block.heading}>
              <SolutionsIcon heading={block.heading} />
              <CopyHeading>{block.heading}</CopyHeading>
              <BodyText>{block.copy}</BodyText>
            </div>
          ))}
        </div>
      </Container>
      <svg
        css={cutoutCSS}
        viewBox="0 0 1280 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 0,100 Q 640,0 1280,100 Z" />
        <line x1="0" y1="100" x2="1280" y2="100" stroke="#fff" />
      </svg>
    </section>
  );
};

export default memo(SupportedSolutions);

const baseCSS = css`
  background: #f8f8f8;
  padding: 3.125rem 0 8.625rem;
  position: relative;
  text-align: center;
`;

const bodyTextCSS = css`
  margin-bottom: 4rem;
  text-align: left;
`;

const blocksCSS = css`
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1.125rem 2.5rem;

  @media ${viewports.medium} {
    display: flex;
  }
`;

const blockCSS = css`
  margin-bottom: 2.125rem;
  padding: 0 1.125rem;
  text-align: left;

  @media ${viewports.medium} {
    width: 33.3333%;
  }
`;

const cutoutCSS = css`
  bottom: 0;
  fill: #fff;
  left: 0;
  height: auto;
  position: absolute;
  right: 0;
  width: 100%;
`;
