import React, { memo } from 'react';
import { css } from '@emotion/core';
import Img from 'gatsby-image';

const BackgroundImage = ({ image, style }) => {
  return (
    <div
      css={css`
        ${baseCSS}
        ${style}
      `}
    >
      <Img css={imageCSS} fluid={image.localFile.childImageSharp.fluid} />
    </div>
  );
};

export default memo(BackgroundImage);

const baseCSS = css`
  overflow: hidden;
  position: relative;
`;

const imageCSS = css`
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
