import React, { memo } from 'react';
import { css } from '@emotion/core';

const SolutionsIcon = ({ heading }) => {
  if (heading === 'Electricity') {
    return (
      <svg
        css={baseCSS}
        height="50"
        viewBox="0 0 48 50"
        width="48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="">
          <path
            d="m24.0566038 8.49056604c-8.3367925 0-15.09433965 6.75849056-15.09433965 15.09433966 0 6.6877358 4.35377355 12.35 10.37735845 14.3320754v9.2528302h1.8867925v2.8301887h5.6603774v-2.8301887h1.8867924v-9.2528302c6.0235849-1.9820754 10.3773585-7.6443396 10.3773585-14.3320754 0-8.3358491-6.7575472-15.09433966-15.0943396-15.09433966z"
            fill="#efce4a"
          />
          <g fill="#efce4a">
            <path d="m24.0566038 0c-.5216981 0-.9433963.42169811-.9433963.94339623v3.7735849c0 .52169812.4216982.94339623.9433963.94339623s.9433962-.42169811.9433962-.94339623v-3.7735849c0-.52169812-.4216981-.94339623-.9433962-.94339623z" />
            <path d="m46.6981132 22.6415094h-3.7735849c-.5216981 0-.9433962.4216981-.9433962.9433963 0 .5216981.4216981.9433962.9433962.9433962h3.7735849c.5216981 0 .9433962-.4216981.9433962-.9433962 0-.5216982-.4216981-.9433963-.9433962-.9433963z" />
            <path d="m5.18867925 22.6415094h-3.77358491c-.52169811 0-.94339623.4216981-.94339623.9433963 0 .5216981.42169812.9433962.94339623.9433962h3.77358491c.52169811 0 .94339622-.4216981.94339622-.9433962 0-.5216982-.42169811-.9433963-.94339622-.9433963z" />
            <path d="m39.4 6.90754717-2.6679245 2.66792453c-.368868.36886792-.368868.9650943 0 1.3339623.1839622.1839622.4254717.2764151.6669811.2764151s.4830189-.0924529.6669811-.2764151l2.6679246-2.66792457c.3688679-.36886792.3688679-.96509434 0-1.33396226-.368868-.36886792-.9660378-.36792453-1.3339623 0z" />
            <path d="m10.0471698 36.2603774-2.66792452 2.6679245c-.36886792.3688679-.36886792.9650943 0 1.3339623.18396227.1839622.4254717.276415.66698114.276415.24150943 0 .48301886-.0924528.66698113-.276415l2.66792455-2.6679246c.3688679-.3688679.3688679-.9650943 0-1.3339622-.3688679-.368868-.9650944-.368868-1.3339623 0z" />
            <path d="m38.0660377 36.2603774c-.3688679-.368868-.9650943-.368868-1.3339622 0-.368868.3688679-.368868.9650943 0 1.3339622l2.6679245 2.6679246c.1839623.1839622.4254717.276415.6669811.276415.2415095 0 .4830189-.0924528.6669812-.276415.3688679-.368868.3688679-.9650944 0-1.3339623z" />
            <path d="m8.71320755 6.90754717c-.36886793-.36886792-.96509434-.36886792-1.33396227 0-.36886792.36886792-.36886792.96509434 0 1.33396226l2.66792452 2.66792457c.1839623.1839622.4254717.2764151.6669811.2764151.2415095 0 .4830189-.0924529.6669812-.2764151.3688679-.368868.3688679-.96509438 0-1.3339623z" />
          </g>
          <path
            d="m13.6792453 24.5283019c-.5216981 0-.9433962-.4216981-.9433962-.9433962 0-6.2424529 5.0783018-11.3207548 11.3207547-11.3207548.5216981 0 .9433962.4216982.9433962.9433963s-.4216981.9433962-.9433962.9433962c-5.2018868 0-9.4339623 4.2320755-9.4339623 9.4339623 0 .5216981-.4216981.9433962-.9433962.9433962z"
            fill="#f7e6a1"
          />
          <path
            d="m19.3396226 40.5660377v6.6037736h1.8867925v2.8301887h5.6603774v-2.8301887h1.8867924v-6.6037736z"
            fill="#556080"
          />
        </g>
      </svg>
    );
  }

  if (heading === 'Gas') {
    return (
      <svg
        css={baseCSS}
        height="50"
        viewBox="0 0 50 50"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="">
          <path
            d="m20.9940358 16.7992048c0 1.6699801-1.3518887 3.1013916-3.1013917 3.1013916h-.1590457c-1.6699801 0-3.1013916-1.3518886-3.1013916-3.1013916v-13.67793243c0-1.74950299 1.3518886-3.10139165 3.1013916-3.10139165h.1590457c1.749503 0 3.1013917 1.35188866 3.1013917 3.10139165z"
            fill="#b2b1a1"
          />
          <path
            d="m17.8926441.01988072c1.749503 0 3.1013917 1.35188866 3.1013917 3.10139165v13.51888663c0 1.6699802-1.4314115 3.1013917-3.1013917 3.1013917"
            fill="#969587"
          />
          <g fill="#d8d5c5" transform="translate(15.407555 3.976143)">
            <path d="m4.79125249.65606362c0 .07952286-.07952287.15904572-.15904573.15904572h-4.37375746c-.15904572 0-.23856858-.07952286-.23856858-.15904572v-.47713718c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.15904572 0 .23856859.07952286.23856859.15904572z" />
            <path d="m4.79125249 3.83697813c0 .07952286-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952287-.15904572-.15904573v-.39761431c0-.07952287.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952286.15904573.15904573z" />
            <path d="m4.79125249 7.01789264c0 .07952287-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952286-.15904572-.15904573v-.39761431c0-.07952286.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952287.15904573.15904573z" />
            <path d="m4.79125249 10.1988072c0 .0795228-.07952287.1590457-.15904573.1590457h-4.37375746c-.07952286 0-.15904572-.0795229-.15904572-.1590457v-.39761436c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.07952286 0 .15904573.07952286.15904573.15904572z" />
          </g>
          <path
            d="m28.1510934 16.7992048c0 1.6699801-1.3518886 3.1013916-3.1013916 3.1013916h-.1590457c-1.6699802 0-3.1013917-1.3518886-3.1013917-3.1013916v-13.67793243c0-1.74950299 1.3518887-3.10139165 3.1013917-3.10139165h.1590457c1.749503 0 3.1013916 1.35188866 3.1013916 3.10139165z"
            fill="#b2b1a1"
          />
          <path
            d="m25.0497018.01988072c1.749503 0 3.1013916 1.35188866 3.1013916 3.10139165v13.51888663c0 1.6699802-1.4314115 3.1013917-3.1013916 3.1013917"
            fill="#969587"
          />
          <g fill="#d8d5c5" transform="translate(22.564612 3.976143)">
            <path d="m4.79125249.65606362c0 .07952286-.07952287.15904572-.15904573.15904572h-4.37375746c-.15904572 0-.23856858-.07952286-.23856858-.15904572v-.47713718c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.15904572 0 .23856859.07952286.23856859.15904572z" />
            <path d="m4.79125249 3.83697813c0 .07952286-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952287-.15904572-.15904573v-.39761431c0-.07952287.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952286.15904573.15904573z" />
            <path d="m4.79125249 7.01789264c0 .07952287-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952286-.15904572-.15904573v-.39761431c0-.07952286.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952287.15904573.15904573z" />
            <path d="m4.79125249 10.1988072c0 .0795228-.07952287.1590457-.15904573.1590457h-4.37375746c-.07952286 0-.15904572-.0795229-.15904572-.1590457v-.39761436c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.07952286 0 .15904573.07952286.15904573.15904572z" />
          </g>
          <path
            d="m35.3081511 16.7992048c0 1.6699801-1.3518887 3.1013916-3.1013917 3.1013916h-.1590457c-1.6699801 0-3.1013916-1.3518886-3.1013916-3.1013916v-13.67793243c0-1.74950299 1.3518886-3.10139165 3.1013916-3.10139165h.1590457c1.749503 0 3.1013917 1.35188866 3.1013917 3.10139165z"
            fill="#b2b1a1"
          />
          <path
            d="m32.2067594.01988072c1.749503 0 3.1013917 1.35188866 3.1013917 3.10139165v13.51888663c0 1.6699802-1.4314115 3.1013917-3.1013917 3.1013917"
            fill="#969587"
          />
          <g fill="#d8d5c5" transform="translate(29.72167 3.976143)">
            <path d="m4.79125249.65606362c0 .07952286-.07952287.15904572-.15904573.15904572h-4.37375746c-.15904572 0-.23856858-.07952286-.23856858-.15904572v-.47713718c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.15904572 0 .23856859.07952286.23856859.15904572z" />
            <path d="m4.79125249 3.83697813c0 .07952286-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952287-.15904572-.15904573v-.39761431c0-.07952287.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952286.15904573.15904573z" />
            <path d="m4.79125249 7.01789264c0 .07952287-.07952287.15904573-.15904573.15904573h-4.37375746c-.07952286 0-.15904572-.07952286-.15904572-.15904573v-.39761431c0-.07952286.07952286-.15904573.15904572-.15904573h4.37375746c.07952286 0 .15904573.07952287.15904573.15904573z" />
            <path d="m4.79125249 10.1988072c0 .0795228-.07952287.1590457-.15904573.1590457h-4.37375746c-.07952286 0-.15904572-.0795229-.15904572-.1590457v-.39761436c0-.07952286.07952286-.15904572.15904572-.15904572h4.37375746c.07952286 0 .15904573.07952286.15904573.15904572z" />
          </g>
          <path
            d="m39.6023857 19.5029821c0 1.5904573-1.2723658 2.7833002-2.9423459 2.7833002h-24.0954275c-1.5904572 0-2.9423459-1.1928429-2.9423459-2.7833002 0-1.5904572 1.2723658-2.7833002 2.9423459-2.7833002h24.0954275c1.5904572 0 2.9423459 1.192843 2.9423459 2.7833002z"
            fill="#e29e3b"
          />
          <path
            d="m36.6600398 16.7196819c1.5904572 0 2.9423459 1.0337972 2.9423459 2.7037773 0 1.5904573-1.2723658 2.8628231-2.9423459 2.8628231h-24.0954275c-1.5904572 0-2.9423459-1.2723658-2.9423459-2.8628231"
            fill="#c68d30"
          />
          <path
            d="m45.6461233 35.0099404c0 4.3737574-3.5785289 7.9522862-7.9522863 7.9522862h-25.4473161c-4.37375748 0-7.95228631-3.5785288-7.95228631-7.9522862v-7.9522863c0-4.3737575 3.57852883-7.9522863 7.95228631-7.9522863h25.4473161c4.3737574 0 7.9522863 3.5785288 7.9522863 7.9522863z"
            fill="#ce1906"
          />
          <path
            d="m45.6461233 27.0576541v7.9522863c0 4.3737574-3.5785289 7.9522862-7.9522863 7.9522862h-25.4473161c-4.37375748 0-7.95228631-4.1351888-7.95228631-8.5089463v-7.3956262"
            fill="#b51815"
          />
          <path
            d="m49.9403579 41.3717694c0 4.4532803-3.5785289 7.9522863-8.1113321 7.9522863h-33.71769379c-4.45328032 0-8.11133201-3.499006-8.11133201-7.9522863s3.57852883-7.9522863 8.03180915-7.9522863h33.71769385c4.5328032 0 8.1908549 3.499006 8.1908549 7.9522863z"
            fill="#b2b1a1"
          />
          <path
            d="m49.9403579 41.4512922c0 4.4532804-3.5785289 7.8727635-8.1113321 7.8727635h-33.71769379c-4.45328032 0-8.11133201-3.499006-8.11133201-7.9522863"
            fill="#969587"
          />
          <circle cx="13.836978" cy="26.898608" fill="#eefcff" r="4.691849" />
          <path
            d="m13.8369781 22.2862823c2.5447316 0 4.6918489 2.0675944 4.6918489 4.6918489s-2.0675944 4.6918489-4.6918489 4.6918489"
            fill="#def0f9"
          />
          <g fill="#aac3ce" transform="translate(9.741551 22.763419)">
            <path d="m4.1749503 1.19284294c0 .07952287 0 .07952287-.07952286.07952287-.07952287 0-.07952287 0-.07952287-.07952287v-1.03379721c0-.07952287 0-.07952287.07952287-.07952287.07952286 0 .07952286 0 .07952286.07952287z" />
            <path d="m4.1749503 8.11133201c0 .07952286 0 .07952286-.07952286.07952286-.07952287 0-.07952287 0-.07952287-.07952286v-1.03379722c0-.07952286 0-.07952286.07952287-.07952286.07952286 0 .07952286 0 .07952286.07952286z" />
            <path d="m7.03777336 4.29423459c-.07952286 0-.07952286 0-.07952286-.07952286s0-.07952286.07952286-.07952286h1.03379722c.07952286 0 .07952286 0 .07952286.07952286s0 .07952286-.07952286.07952286z" />
            <path d="m.11928429 4.29423459c-.07952286 0-.07952286 0-.07952286-.07952286s0-.07952286.07952286-.07952286h1.03379722c.07952286 0 .07952286 0 .07952286.07952286s0 .07952286-.07952286.07952286z" />
            <path d="m6.24254473 2.1471173s-.07952286 0-.15904572 0c0 0 0-.07952287 0-.15904573l.71570576-.71570576h.15904573v.15904572z" />
            <path d="m1.3916501 6.99801193s-.07952286 0-.15904573 0c0 0 0-.07952286 0-.15904573l.71570577-.71570576h.15904572v.15904572z" />
            <path d="m6.08349901 6.28230616s0-.07952286 0-.15904572h.15904572l.71570577.71570576v.15904573s-.07952287 0-.15904573 0z" />
            <path d="m1.23260437 1.43141153s0-.07952286 0-.15904572h.15904573l.71570576.71570576v.15904573s-.07952286 0-.15904572 0z" />
          </g>
          <path
            d="m6.75944334 34.8508946c0-.3976143.31809145-.6361829.71570577-.6361829h34.99005959c.3976144 0 .7157058.2385686.7157058.6361829"
            fill="#d8d5c5"
          />
          <g fill="#dddccc" transform="translate(3.479125 35.28827)">
            <circle cx="6.143141" cy="6.322068" r="4.930418" />
            <path d="m5.586481.198807h1.590457v2.385686h-1.590457z" />
            <path d="m5.586481 10.059642h1.590457v2.385686h-1.590457z" />
            <path
              d="m8.93981612 1.48260258h1.66996408v2.54470721h-1.66996408z"
              transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 14.738576 11.61483)"
            />
            <path
              d="m1.6831023 8.6683053h1.66996411v2.5447072h-1.66996411z"
              transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 -2.730469 18.750321)"
            />
            <path d="m10.357853 5.28827h2.147117v1.590457h-2.147117z" />
            <path d="m.019881 5.28827h2.385686v1.590457h-2.385686z" />
            <path
              d="m8.89541828 8.66486915h1.66996412v2.54470725h-1.66996412z"
              transform="matrix(.70710678 -.70710678 .70710678 .70710678 -4.17671 9.790977)"
            />
            <path
              d="m1.78400442 1.47141631h1.67018194v2.54508648h-1.67018194z"
              transform="matrix(.70755662 -.70665666 .70665666 .70755662 -1.173101 2.653254)"
            />
            <circle cx="36.838966" cy="6.322068" r="4.930418" />
            <path d="m35.805169.039761h1.590457v2.385686h-1.590457z" />
            <path d="m35.805169 10.059642h1.590457v2.385686h-1.590457z" />
            <path
              d="m39.5685285 1.45363707h1.6699641v2.54470721h-1.6699641z"
              transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 67.045541 33.223154)"
            />
            <path
              d="m32.3663938 8.69082333h1.6699641v2.54470717h-1.6699641z"
              transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 49.633264 40.485125)"
            />
            <path d="m40.576541 5.28827h2.385686v1.590457h-2.385686z" />
            <path d="m30.238569 5.28827h2.385686v1.590457h-2.385686z" />
            <path
              d="m39.5804155 8.62801342h1.6699641v2.54470718h-1.6699641z"
              transform="matrix(.70710678 -.70710678 .70710678 .70710678 4.836779 31.477752)"
            />
            <path
              d="m32.4143282 1.5033482h1.6699641v2.5447072h-1.6699641z"
              transform="matrix(.70710678 -.70710678 .70710678 .70710678 7.77578 24.323797)"
            />
            <circle cx="21.491054" cy="6.322068" r="4.930418" />
            <path
              d="m15.8543333 6.69901976h1.6700001v2.54476205h-1.6700001z"
              transform="matrix(.31919618 .94768866 -.94768866 .31919618 18.916568 -10.389331)"
            />
            <path
              d="m25.4652921 3.47594133h1.6699485v2.54473555h-1.6699485z"
              transform="matrix(.31808708 .9480615 -.9480615 .31808708 22.43618 -21.696336)"
            />
            <path
              d="m16.09475 2.84551084h1.6700106v2.54477816h-1.6700106z"
              transform="matrix(-.44469186 .89568362 -.89568362 -.44469186 28.146615 -9.214608)"
            />
            <path
              d="m25.1921285 7.40286445h1.670013v2.54488101h-1.670013z"
              transform="matrix(-.44429126 .8958824 -.8958824 -.44429126 45.362817 -10.787585)"
            />
            <path
              d="m19.0301042.28247805h1.6701512v2.54494024h-1.6701512z"
              transform="matrix(-.94775932 .31898631 -.31898631 -.94775932 39.188597 -3.308056)"
            />
            <path
              d="m22.285434 9.89610549h1.6701512v2.54494021h-1.6701512z"
              transform="matrix(-.94775932 .31898631 -.31898631 -.94775932 48.595812 14.378572)"
            />
            <path
              d="m22.9291213.52181746h1.6699327v2.54456h-1.6699327z"
              transform="matrix(-.89532541 -.44541263 .44541263 -.89532541 44.241566 13.985223)"
            />
            <path
              d="m18.3800257 9.65619395h1.6700107v2.54477815h-1.6700107z"
              transform="matrix(-.89568362 -.44469186 .44469186 -.89568362 31.565768 29.261904)"
            />
          </g>
          <circle cx="9.622266" cy="41.610338" fill="#ff4133" r="2.306163" />
          <path
            d="m9.6222664 39.304175c1.2723658 0 2.306163 1.0337972 2.306163 2.306163s-1.0337972 2.306163-2.306163 2.306163"
            fill="#b51815"
          />
          <circle cx="24.970179" cy="41.610338" fill="#ff4133" r="2.306163" />
          <path
            d="m24.9701789 39.304175c1.2723658 0 2.306163 1.0337972 2.306163 2.306163s-1.0337972 2.306163-2.306163 2.306163"
            fill="#b51815"
          />
          <circle cx="40.318092" cy="41.610338" fill="#ff4133" r="2.306163" />
          <path
            d="m40.3180915 39.304175c1.2723658 0 2.306163 1.0337972 2.306163 2.306163s-1.0337972 2.306163-2.306163 2.306163"
            fill="#b51815"
          />
          <path
            d="m37.693837 20.2982107c0 .2385686-.1590457.3976143-.3976143.3976143h-25.4473161c-.2385686 0-.3976144-.1590457-.3976144-.3976143s.1590458-.3976143.3976144-.3976143h25.4473161c.2385686 0 .3976143.1590457.3976143.3976143z"
            fill="#ff4133"
          />
          <g fill="#870e0e" transform="translate(23.459245 23.956262)">
            <path d="m1.51093439 5.64612326c0 .39761431-.31809145.6361829-.6361829.6361829h-.23856859c-.39761431 0-.6361829-.31809145-.6361829-.6361829v-5.00994036c0-.39761431.31809145-.6361829.6361829-.6361829h.23856859c.39761432 0 .6361829.31809145.6361829.6361829z" />
            <path d="m4.69184891 5.64612326c0 .39761431-.31809145.6361829-.63618291.6361829h-.23856858c-.39761432 0-.63618291-.31809145-.63618291-.6361829v-5.00994036c0-.39761431.31809145-.6361829.63618291-.6361829h.23856858c.39761432 0 .63618291.31809145.63618291.6361829z" />
            <path d="m7.87276342 5.64612326c0 .39761431-.31809145.6361829-.6361829.6361829h-.23856859c-.39761432 0-.6361829-.31809145-.6361829-.6361829v-5.00994036c0-.39761431.31809145-.6361829.6361829-.6361829h.23856859c.39761431 0 .6361829.31809145.6361829.6361829z" />
            <path d="m11.0536779 5.64612326c0 .39761431-.3180914.6361829-.6361829.6361829h-.2385686c-.39761427 0-.63618286-.31809145-.63618286-.6361829v-5.00994036c0-.39761431.31809145-.6361829.63618286-.6361829h.2385686c.3976143 0 .6361829.31809145.6361829.6361829z" />
          </g>
          <path
            d="m14.0755467 27.5347913c0 .0795228-.0795228.2385685-.2385686.2385685-.1590457 0-.2385686-.0795228-.2385686-.2385685v-2.2266402c0-.0795229.0795229-.2385686.2385686-.2385686.1590458 0 .2385686.0795229.2385686.2385686z"
            fill="#ff4133"
          />
        </g>
      </svg>
    );
  }

  if (heading === 'Water') {
    return (
      <svg
        css={baseCSS}
        height="50"
        viewBox="0 0 42 50"
        width="42"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="">
          <path
            d="m23.3306504 21.6460669c-.0037659-.0102491-.0081111-.0200101-.0130357-.0296735l-11.0201796-21.47134445c-.0457698-.08902062-.1366334-.14504895-.2359943-.14504895-.099361 0-.1902245.05602833-.2359943.14504895l-11.02017967 21.47134445c-.00502115.0096634-.00936638.019522-.01303569.0296735-1.16143175 3.1435407-1.04256556 6.5559002.33496897 9.6084681 1.37743796 3.0527631 3.84861865 5.3802814 6.95825849 6.5541433 1.27363525.4808284 2.60849.7459381 3.9678711.7877153.0027037.0000976.0054074.0000976.0081111.0000976s.0054074 0 .0081111-.0000976c1.3592845-.0419724 2.6942358-.3069845 3.9676779-.7877153 3.1095433-1.1738619 5.5808206-3.5014778 6.9583551-6.5541433 1.377438-3.0525679 1.4964973-6.4649274.3350655-9.6084681z"
            fill="#68ccea"
          />
          <g fill="#5abce2" transform="translate(0 .438596)">
            <path d="m13.0809666 35.9581358c-2.6600857-2.6174568-4.14011506-6.1129336-4.16763285-9.8425037-.00009724-.0119425.00058341-.0240793.00204195-.0360218l2.9825203-26.05378333-11.00750581 21.18491363c-.00505628.0096123-.0094319.0194188-.01312687.0295165-1.16955478 3.1269084-1.04985725 6.5212134.33731174 9.5576303 1.38707175 3.0366111 3.87553583 5.3518146 7.00692444 6.5194656 1.28254304.4782845 2.6267337.7419914 3.9956223.7835476.0027226.0000971.0054452.0000971.0081678.0000971s.0054453 0 .0081679-.0000971c.9979332-.0303904 1.9825451-.1802062 2.9399309-.443719-.742397-.4930427-1.4430797-1.0603623-2.0924218-1.6990458z" />
            <path d="m40.7500579 26.9707379c-.0037922-.0100978-.0081678-.0199042-.0131268-.0295165l-5.4717623-10.5308993c-.0460899-.0885496-.1375889-.1442815-.2376448-.1442815s-.191555.0556348-.2376448.1442815l-5.4717623 10.5308993c-.0050562.0096123-.0094319.0194187-.0131268.0295165-.5893475 1.5756395-.5289639 3.2860453.1700658 4.8160506.6988352 1.5301025 1.9527908 2.6967825 3.5309314 3.2852686.6462305.2408899 1.3236738.3737143 2.0133689.3947837.0027226.0000971.0054452.0000971.0081678.0000971s.0054452 0 .0081678-.0000971c.6895006-.0210694 1.3669439-.1538938 2.0132717-.3947837 3.2569203-1.2145474 4.9173225-4.8488684 3.7010944-8.1013192z" />
          </g>
          <path
            d="m40.8272508 27.3011965c-.0038123-.0100316-.0082112-.0197738-.0131966-.0293231l-5.5008566-10.461871c-.0463349-.0879692-.1383205-.1433357-.2389084-.1433357-.0004888 0-.0010753.0001929-.0016618.0001929l-2.1654252 12.4257446c-.0020528.0116713-.0033236.0234391-.0038124.0352069-.0916923 1.8392506.547808 3.6032644 1.8008064 4.967462.5368597.5845321 1.159351 1.0646973 1.8416672 1.4310427.1890544-.0505437.3761537-.108804.5606137-.1768065 3.2742379-1.2066827 4.9434687-4.8171813 3.7207737-8.0483128z"
            fill="#68ccea"
          />
          <path
            d="m29.9786479 44.1055216-3.644461-7.1170785c-.0460721-.0898117-.137536-.1463378-.2375533-.1463378-.1000174 0-.1914812.0565261-.2375534.1463378l-3.6449469 7.1170785c-.0050544.0097493-.0094283.0198924-.0131218.0300356-.8272572 2.2448966.3020932 4.7533194 2.5178321 5.5918567.4396292.1660333.9004477.2578145 1.3695281.2724876.0028188.0000985.0055403.0000985.0082619.0000985s.0054431 0 .0082619-.0000985c.4686916-.0146731.9294129-.1062574 1.3694309-.2725861 2.2156417-.8383403 3.3449921-3.3468616 2.5175405-5.5918566-.0037907-.0103402-.0082618-.0202864-.013219-.0299372z"
            fill="#5abce2"
          />
          <path
            d="m29.9942885 44.1496787c-.0037562-.0103603-.0080903-.0203259-.0130022-.0299956l-3.611245-7.1309547c-.0456522-.0899868-.1362825-.1466231-.2353883-.1466231-.0282196 0-.0552835.0056241-.0812879.0143071l-1.4826378 8.9361161c-.0021189.0120377-.0032747.0245687-.0039489.0369024-.077628 1.6906059.6915253 3.2247218 1.9234611 4.1705691.3444143-.0397639.6828571-.1226464 1.0095498-.2474635 2.1954482-.8401721 3.3145056-3.3535843 2.4944992-5.6028578z"
            fill="#68ccea"
          />
        </g>
      </svg>
    );
  }

  if (heading === 'Industrial Automation') {
    return (
      <svg
        css={baseCSS}
        height="50"
        viewBox="0 0 47 50"
        width="47"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" transform="">
          <path
            d="m35.4322826 5.43478261c.2776087.47978261.4372826 1.03630435.4372826 1.63043478 0 .59413044-.1596739 1.15065218-.4372826 1.63043478h3.6981522v-3.26086956z"
            fill="#404040"
          />
          <path
            d="m9.54706522 5.43478261 1.62576088 3.26086956h14.9141304v-3.26086956z"
            fill="#404040"
          />
          <path
            d="m26.086957 3.804348h3.26087v6.521739h-3.26087z"
            fill="#ff6a00"
          />
          <path
            d="m35.4322826 5.43478261c-.563913-.97434783-1.6167391-1.63043478-2.8235869-1.63043478-1.8009783 0-3.2608696 0-3.2608696 0v6.52173917h3.2608696c1.2067391 0 2.2596739-.656087 2.8235869-1.63043483.2776087-.4797826.4372826-1.03630434.4372826-1.63043478 0-.59413043-.1596739-1.15065217-.4372826-1.63043478z"
            fill="#ffaa19"
          />
          <path
            d="m46.7391304 3.26086957h-2.173913v-3.26086957h-3.2608696v3.26086957h1.6304348v7.60869563h-1.6304348v6.5217391h3.2608696v-6.5217391h2.173913z"
            fill="#404040"
          />
          <path
            d="m39.130435 3.26087h3.804348v7.608696h-3.804348z"
            fill="#606060"
          />
          <path
            d="m14.1073913 36.9565217h-8.15217391l-.72467391 3.2608696h8.87684782z"
            fill="#bfbfbf"
          />
          <path
            d="m14.1073913 43.4782609h-9.60152173l-.72456522 3.2608695h10.32608695z"
            fill="#bfbfbf"
          />
          <path
            d="m14.1073913 46.7391304h10.326087l-.724674-3.2608695h-9.601413z"
            fill="#a3a3a3"
          />
          <path
            d="m14.1073913 40.2173913h8.8767391l-.7245652-3.2608696h-8.1521739z"
            fill="#a3a3a3"
          />
          <path
            d="m14.1073913 30.4346739c-1.7947826-.001413-3.5220652-.9932609-4.3773913-2.7086956l-2.90978261-5.836087c-.55228261 1.0992391-.865 2.3395652-.865 3.653587v8.1521739h8.15217391z"
            fill="#ffaa19"
          />
          <path
            d="m16.2897826 29.9208696-1.455-2.9182609c-.8058696.4018478-1.7848913.0742391-2.1866304-.7316304-.4018479-.8058696-.0742392-1.7847826.7316304-2.1866305l-7.75847825-15.56119563c-.80728261.4026087-1.78630435.075-2.18804348-.73086956-.12565217-.25195652-.1798913-.52086957-.17065217-.78380435.01923913-.57923913.34717391-1.12978261.9022826-1.40652174l-1.455-2.91826087c-2.41760869 1.20543478-3.40032608 4.14228261-2.1948913 6.56l6.30521739 12.64619565 2.90978261 5.836087c.8553261 1.7154347 2.5826087 2.7072826 4.3773913 2.7086956v3.2609783h8.1521739s0-3.6498913 0-8.1521739c0-4.0008696-2.8829348-7.326087-6.6841304-8.0178261l2.9093478 5.8353261c1.2053261 2.4176087.2226087 5.3545652-2.195 6.5598913z"
            fill="#ff6a00"
          />
          <path d="m1.607391 46.73913h12.5v3.26087h-12.5z" fill="#606060" />
          <path d="m14.107391 46.73913h12.5v3.26087h-12.5z" fill="#404040" />
          <path
            d="m3.781304 40.217391h10.326087v3.26087h-10.326087z"
            fill="#8f8f8f"
          />
          <path
            d="m14.107391 40.217391h10.326087v3.26087h-10.326087z"
            fill="#606060"
          />
          <path
            d="m5.955217 33.695652h8.152174v3.26087h-8.152174z"
            fill="#8f8f8f"
          />
          <path
            d="m14.107391 33.695652h8.152174v3.26087h-8.152174z"
            fill="#606060"
          />
          <path
            d="m9.26978261 4.87869565c-1.20532609-2.41760869-4.14228261-3.40032608-6.55989131-2.195l1.455 2.91826087c.80586957-.40184782 1.78489131-.07423913 2.18663044.73163044.12565217.25195652.1798913.52086956.17065217.78380434-.01923913.57869566-.34663043 1.12880435-.90076087 1.40576087l7.75847826 15.56119563c.8058696-.4018478 1.7848913-.0742391 2.1866304.7316305.4017392.8058695.0742392 1.7847826-.7316304 2.1866304l1.455 2.9182609c2.4176087-1.2053261 3.4002174-4.1422826 2.1948913-6.5598913l-2.9093478-5.8353261-4.4026087-8.83000003-1.62576088-3.26086956z"
            fill="#ff3d0d"
          />
          <g fill="#404040">
            <circle cx="14.107391" cy="25.543478" r="1.630435" />
            <path d="m3.2626087 7.00858696c-.00934783.26293478.045.53184782.17065217.78380434.40173913.80586957 1.38076087 1.13347827 2.18663043.73163044.55554348-.27782609.88304348-.82782609.90228261-1.40652174.00934783-.26282609-.045-.53184783-.17065217-.78380435-.40173913-.80586956-1.38076087-1.13347826-2.18663044-.73163043-.55510869.27663043-.88304347.82717391-.9022826 1.40652174z" />
          </g>
        </g>
      </svg>
    );
  }

  return null;
};

export default memo(SolutionsIcon);

const baseCSS = css`
  margin-bottom: 0.875rem;
`;
