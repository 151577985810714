import React, { memo } from 'react';
import { css } from '@emotion/core';
import { SectionHeading } from '../styled/Typography';
import Container from '../styled/Container';
import BackgroundImage from '../styled/BackgroundImage';
import { CopyHeading, BodyText } from '../styled/Typography';
// import Button from '../styled/Button';
import { viewports } from '../../utils/variables';

const WhatWeDo = ({ heading, blocks, cta, ctaUrl }) => {
  return (
    <section css={baseCSS}>
      <Container theme="secondary">
        <SectionHeading>{heading}</SectionHeading>
        <div css={blocksCSS}>
          {blocks.map((block, index) => (
            <div
              key={block.heading}
              css={css`
                ${rowCSS}
                flex-direction: ${index % 2 === 0 ? 'row' : 'row-reverse'};
              `}
            >
              <BackgroundImage style={imageCSS} image={block.image} />
              <div css={contentCSS}>
                <CopyHeading>{block.heading}</CopyHeading>
                <BodyText>{block.copy}</BodyText>
              </div>
            </div>
          ))}
        </div>
        {/* <Button url={ctaUrl}>{cta}</Button> */}
      </Container>
    </section>
  );
};

export default memo(WhatWeDo);

const baseCSS = css`
  background: #fff;
  padding: 3.75rem 0 3.125rem;
  text-align: center;
`;

const blocksCSS = css`
  margin: 0 auto 2.5rem;
`;

const rowCSS = css`
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.75rem;
  text-align: left;

  @media ${viewports.medium} {
    display: flex;
  }
`;

const contentCSS = css`
  @media ${viewports.medium} {
    width: 50%;
  }
`;

const imageCSS = css`
  height: 18.75rem;
  margin-bottom: 0.875rem;

  @media ${viewports.medium} {
    margin-bottom: 0;
    width: 40%;
  }
`;
