import React, { memo } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/shared/Layout';
import Hero from '../components/homepage/Hero';
import WhatWeDo from '../components/homepage/WhatWeDo';
import SupportedSolutions from '../components/homepage/SupportedSolutions';
import OurProducts from '../components/homepage/OurProducts';
import BottomExcerpt from '../components/homepage/BottomExcerpt';

const IndexPage = ({ data }) => {
  const {
    wordpressPage: { acf }
  } = data;
  return (
    <Layout theme="secondary">
      <Hero heading={acf.hero_heading} subheading={acf.hero_subheading} />
      <WhatWeDo
        heading={acf.what_we_do_heading}
        blocks={acf.what_we_do_blocks}
        cta={acf.what_we_do_cta}
        ctaUrl={acf.what_we_do_cta_url}
      />
      <SupportedSolutions
        heading={acf.supported_solutions_heading}
        copy={acf.supported_solutions_copy}
        blocks={acf.supported_solutions_blocks}
      />
      <OurProducts
        heading={acf.our_products_heading}
        blocks={acf.our_products_blocks}
      />
      <BottomExcerpt
        copy={acf.bottom_excerpt_copy}
        image={acf.bottom_excerpt_image}
      />
    </Layout>
  );
};

export default memo(IndexPage);

export const query = graphql`
  query indexQuery($page_id: Int = 8) {
    wordpressPage(wordpress_id: { eq: $page_id }) {
      title
      acf {
        hero_heading
        hero_subheading
        what_we_do_heading
        what_we_do_blocks {
          heading
          copy
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        what_we_do_cta
        what_we_do_cta_url
        supported_solutions_heading
        supported_solutions_copy
        supported_solutions_blocks {
          heading
          copy
        }
        our_products_heading
        our_products_blocks {
          heading
          copy
          cta
          cta_url
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        bottom_excerpt_copy
        bottom_excerpt_image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
