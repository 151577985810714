import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { colors, viewports } from '../../utils/variables';

export const SectionHeading = ({ children, style }) => (
  <h3
    css={css`
      color: ${colors.black};
      font-size: 2.25rem;
      font-weight: 700;
      margin: 0 0 3.125rem;
      ${style}
    `}
  >
    {children}
  </h3>
);

export const CopyHeading = ({ children, style, theme }) => (
  <h4
    css={css`
      color: ${colors.black};
      font-family: 'Titillium Web';
      font-size: ${theme === 'large' ? '1.875rem' : '1.5rem'};
      font-weight: 700;
      margin: 0 0 0.875rem;
      ${style}
    `}
  >
    {children}
  </h4>
);

export const BodyText = ({ children, style, theme }) => (
  <div
    css={css`
      color: ${colors.gray};
      font-size: ${theme === 'large' ? '1.125rem' : '1rem'};
      line-height: 150%;
      ${style}
    `}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);

export const Cta = ({ to, children, style, isPrev }) => (
  <Link
    css={css`
      color: ${colors.blue};
      font-size: 1.125rem;
      font-weight: 700;
      text-decoration: none;

      ${isPrev &&
        `&:before {
          content: '<';
          display: inline-block;
          margin-right: 0.25rem;
          position: relative;
          transform: translate3d(0, 0, 0);
          transition: transform 300ms ease-out;
        }`}

      ${!isPrev &&
        `&:after {
          content: '>';
          display: inline-block;
          margin-left: 0.25rem;
          position: relative;
          transform: translate3d(0, 0, 0);
          transition: transform 300ms ease-out;
        }`}

      @media ${viewports.medium} {
        &:hover {
          color: ${colors.darkBlue};

          ${isPrev &&
            `&:before {
            transform: translate3d(-0.3rem, 0, 0);
          }`}

          ${!isPrev &&
            `&:after {
            transform: translate3d(0.3rem, 0, 0);
          }`}
        }
      }

      ${style}
    `}
    to={to}
  >
    {children}
  </Link>
);
